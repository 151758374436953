import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import sample1 from "../../Images/sample1.png"
import sample2 from "../../Images/sample2.png"
import sample3 from "../../Images/sample3.png"
import sample4 from "../../Images/sample4.png"
import sample5 from "../../Images/sample5.png"
import sample6 from "../../Images/sample6.png"
import sample7 from "../../Images/sample7.png"
import sample8 from "../../Images/sample8.png"
import sample9 from "../../Images/sample9.png"
import sample10 from "../../Images/sample10.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Unsere <strong className="purple">Designs </strong>
        </h1>
        <p style={{ color: "white" }}>
          Hier finden Sie eine Auswahl unsere <strong className="purple">Designs</strong>.
          <br/>Sie bekommen von uns natürlich <strong className="purple">individuell zugeschnittene Designvorschläge</strong>.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample1}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample2}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample3}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample4}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample5}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample6}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample7}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample9}
              isBlog={false}
              title=""
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sample10}
              isBlog={false}
              title=""
             
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
