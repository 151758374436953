import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Willkommen bei <span className="purple">GA IT Service</span>, Ihrer führenden Webentwicklungsagentur für die digitale Transformation.
          <br/> In einer Welt, die von ständigem Wandel und Innovation geprägt ist, ist der Einsatz <span className="purple">modernster Technologien </span>nicht nur von Vorteil, sondern unerlässlich für Unternehmen, die in der heutigen digitalen Landschaft erfolgreich sein wollen. 
          <br/>
          <br/>
          </p>
          
          <p style={{ color: "rgb(155 126 172)" }}>
            "The function of design is letting design function"{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
