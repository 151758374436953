import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Unser <span className="purple"> ANGEBOT </span> 
            </h1>
            <p className="home-about-body">
              Wir unterstützen Sie bei der <span className="purple">Digitalisierung</span> ihres Unternehmens
              <br />
              <br />Dies beinhaltet den Aufbau von <span className="purple">Websites</span>, die Beratung zu <span className="purple">LEAD Strategien</span>, den einsatz von <span className="purple">KI</span> in ihrem Unternehmen sowie das gemeinsame Erarbeiten von <span className="purple">Social Media Strategien</span>
              <br/>
              <br/>Desweiteren beinhaltet unser Service <span className="purple">Branding & Corporate Design</span>
              <br/>
              <br/>Hierfür nutzen wir modernste Webtechnologien wie <span className="purple">React.js, Vue.js, Next.js, Angular, GraphQL, SQL</span> sowie <span className="purple">NoSQL Databases</span> und vieles mehr...
              <br />
              <br />Gemeinsam bereiten wir Ihr Unternehmen auf des digitale Zeitalter vor. 
              <br />Damit Sie sich auf das wesentliche konzentrieren können.
              <br />
              <br />
            
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1></h1>
            <p>
            <span className="purple">Kontaktieren</span> Sie uns  
            </p>
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/101459486/admin/feed/posts/?feedType=following"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ga_itservice/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
