import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import Particle from "../Particle";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Hier können Sie die Logik zum Senden des Formulars implementieren
    console.log(formData);
    // Beispiel: 
    // axios.post('/send-email', formData)
    //   .then(response => {
    //     console.log(response);
    //     // Hier können Sie eine Erfolgsmeldung anzeigen oder die Seite aktualisieren, etc.
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     // Hier können Sie eine Fehlermeldung anzeigen oder eine andere Behandlung durchführen
    //   });
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Angaben nach Paragrph 5 TMG:

        </h1>
        <p style={{ color: "white" }}>
          GA IT Service
          </p>   
          <h1 className="project-heading">
          Postanschrift:

        </h1>
        <p style={{ color: "white" }}>
          Weinmeisterhornweg 170C, 13593 Berlin
          </p>       <h1 className="project-heading">
         Vertreten durch:

        </h1>
        <p style={{ color: "white" }}>
         Finn Bärenwalde
          </p>     <h1 className="project-heading">
          Umsatzsteuer-Identifikationsnummer
        </h1>
        <p style={{ color: "white" }}>
        42 547 836 196          </p>     <h1 className="project-heading">
        Information NACH PARAGRAPH 36 VSBG
        </h1>
        <p style={{ color: "white" }}>
        NACH PARAGRAPH 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz üeber die alternative Streitbeilegung in Verbrauchersachen) erklaert der Betreiber dieser Website:Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.          </p>      </Container>
    </Container>
  );
}

export default Contact;
